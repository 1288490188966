import axios from "axios";
import qs from'qs';
const Chest = {
  reliefChest() {
    return axios.get("/api/ppy/usercenter/vip/reliefChest");
  },
  memberChest() {
    return axios.get("/api/ppy/usercenter/conchCoin/vip/memberChest");
  },
  openMemberChest() {
    return axios.get("/api/ppy/usercenter/conchCoin/vip/openMemberChest");
  },
  getPrize(privilegeId) {
    return axios.post(
      "/api/ppy/usercenter/vip/getPrize",
      qs.stringify({ privilegeId })
    );
  },
  conchVipAvailableTime() {
    return axios.get("/api/ppy/usercenter/conchCoin/vip/conchVipAvailableTime");
  },
  reliefShareActionLog(id) {
    return axios.get("/api/ppy/usercenter/invite/reliefShareActionLog",{
      params:{
        privilegeId:id
      }
    });
  },
  conchCoinVipCardList() {
    return axios.get("/api/ppy/usercenter/conchCoin/vip/conchCoinVipCardList");
  },
  createOrder(memberCardId) {
    return axios.post(
      "/api/ppy/usercenter/conchCoin/vip/createOrder",
      qs.stringify({ memberCardId })
    );
  },
};
export default Chest;
