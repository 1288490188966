<template>
  <div class="caibei" :style="guide?'height: 100vh;overflow: hidden;':''">
    <div class="caibei-head-vip">
      <div class="headvip"  v-if="(advertisement && advertisement.hasPrivilege && advertisement.privilegeDue)">
        <div @click="exchange(1)">
          免广告特权<span>{{newtimedayS(advertisement.dueTimeStamp-advertisement.currentTimeStamp)}}</span>
        </div>
      </div>
      <div class="caibei-head" :style="advertisement?(advertisement.hasPrivilege && advertisement.privilegeDue)?'':'margin:0 0 8px 0;':'margin:0 0 8px 0;'">
        <div>
          <div class="sleftsdiv">
            <img
              src="https://ppyos.xijiuyou.com/202205/msg-icon.jpg"
              alt=""
            />
            <span>我的彩贝</span>
            <div class="sdiv" ref="showPanel" @click="clone">
              !
              <p v-if="clonelist">
                <span></span
                >彩贝是一种的通用游戏代币，用于游玩彩贝小游戏，可从余额兑换
              </p>
            </div>
            <p class="moneys">{{ money }}</p>
          </div>
          <div class="caibei-head-btn">
            <div>
              <div @click="exchange(0)">兑换彩贝</div>
              <div @click="exchange(1)">彩贝商城</div>
            </div>
            <div  class="gobaox" @click="exchange(2)">救济宝箱</div>
          </div>
        </div>
        <!-- <img  src="https://ppyos.xijiuyou.com/202212/chest-wait.png" alt=""/> -->
      </div>
    </div>
    <!-- <div class="banner" v-if="bannerlist.length > 0" :style="bannerlist.length == 2 || bannerlist.length == 4 ?'height:auto;padding:0;':''">
      <div class="banner-l" v-if="bannerlist.length==5" @click="start(bannerlist[0])">
        <img :src="bannerlist[0].bigicon" alt=""  />
        <p :style="'color:' + bannerlist[0].sgissue + ';'">{{ bannerlist[0].briefintro }}</p>
      </div>
      <div class="banner-r" >
        <template v-for="(item, index) in bannerlist">
          <div
            :key="index"
            v-if="index>=1 && index<3"
            @click="start(item)"
            :style="bannerlist.length === 3 ?'width:100%;text-align: right;':''"
          >
            <img :src="item.bigicon" alt=""/>
            <p :style="'color:' + item.sgissue + ';'">{{ item.briefintro }}</p>
          </div>
          <div
            :key="index"
            v-if="bannerlist.length==2 ||bannerlist.length == 4"
            @click="start(item)"
          >
            <img :src="item.bigicon" alt=""/>
            <p :style="'color:' + item.sgissue + ';'">{{ item.briefintro }}</p>
          </div>
        </template>
      </div>
    </div> -->
    <div class="banner">
      <template v-for="(item, index) in bannerlist">
        <div
          :key="index"
          v-if="index>=0"
           class="banner-l"
          @click="start(item)"
        >
          <img :src="item.bigicon" alt=""/>
          <p :style="'color:' + item.sgissue + ';'">{{ item.briefintro }}</p>
        </div>
      </template>
    </div>
    <!-- <img class="carve-sd" @click="carvesd" src="https://ppyos.xijiuyou.com/202301/entry/ppy-fcard-h5-banner.png" alt=""> -->
    <!-- 擂台赛 -->
      <div class="arena">
        <div class="arena-top">
          <img
            src="https://ppyos.xijiuyou.com/imgs/smartgame/smart-pk.png"
            alt=""
          />
          <p><span>擂台PK赛</span>快！冲榜奖励等着你</p>
        </div>
        <div class="arena—center arena—center-pk">
          <div
            v-for="(item, index) in pklist"
            :key="index"
            @click="arena(item)"
          >
            <img :src="item.icon" alt="" />
            <p>{{ item.sgname }}</p>
            <p>{{ item.showmoney }}{{ unitauthorize('元') }}</p>
          </div>
        </div>
      </div>
      <!-- 赏金小游戏 -->
      <!-- <div class="reward">
        <div class="arena-top">
          <img
            src="https://ppyos.xijiuyou.com/imgs/smartgame/smart-red-pack.png"
            alt=""
          />
          <p><span>赏金小游戏</span>宅家娱乐 边玩边攒钱</p>
        </div>
        <div class="reward-center">
          <div
            v-for="(item, index) in sjlist"
            :key="index"
            @click="reward(item)"
          >
            <img :src="item.icon" alt="" />
            <div>
              <p>{{ item.sgname }}</p>
              <p>{{ item.showmoney }}{{ unitauthorize("元") }}</p>
            </div>
          </div>
        </div>
      </div> -->
      <div class="gameadd" @click="nivergame">
        <img src="https://ppyos.xijiuyou.com/202208/game-icon.png" alt="">
        <span>更多有趣小游戏</span>
        <img src="https://ppyos.xijiuyou.com/202208/right-arrow-icon.png" alt="">
      </div>
      <!-- 排行 -->
    <div class="yesterday" :style="bannerlist.length < 3 ?'margin:0;':''">
      <div class="yesterday-head">
        <div
          @click="yesterday(0)"
          :style="yinterval === 0 ? '' : 'left:auto;right:18px;'"
        >
          {{ yinterval === 0 ? "昨日" : "今日" }}
        </div>
        <img
          src="https://ppyos.xijiuyou.com/202205/cb-gain-everyday-rank.jpg"
          alt=""
        />
      </div>
      <p class="yesterday-titles">每日24点自动结算奖励</p>
      <div class="yesterday-list" v-if="ylist.rankList">
        <div
          v-if="ylist.rankList[1]"
        >
          <img src="https://ppyos.xijiuyou.com/202205/rank-2-icon.jpg" alt="">
          <img :src="ylist.rankList[1].headImg" alt="" />
          <p>
            {{ ylist.rankList[1].nickName
            }}<span class="colors">{{
              ylist.rankList[1].userid == ylist.myRank.userid ? "(我)" : ""
            }}</span>
          </p>
          <p>{{ ylist.rankList[1].userDataFormat }}</p>
          <p><span>奖{{ylist.rankList[1].awardCoinFormat}}</span><img src="https://ppyos.xijiuyou.com/202205/msg-icon.jpg" alt=""></p>
        </div>
        <div
          v-if="ylist.rankList[0]"
        >
          <img src="https://ppyos.xijiuyou.com/202205/rank-1-icon.jpg" alt="">
          <img :src="ylist.rankList[0].headImg" alt="" />
          <p>
            {{ ylist.rankList[0].nickName
            }}<span class="colors">{{
              ylist.rankList[0].userid == ylist.myRank.userid ? "(我)" : ""
            }}</span>
          </p>
          <p>{{ ylist.rankList[0].userDataFormat }}</p>
          <p style="background:rgba(255, 239, 206, 1);color:rgba(240, 144, 0, 1)!important;"><span>奖{{ylist.rankList[0].awardCoinFormat}}</span><img src="https://ppyos.xijiuyou.com/202205/msg-icon.jpg" alt=""></p>
        </div>
        <div
          v-if="ylist.rankList[2]"
          
        >
          <img src="https://ppyos.xijiuyou.com/202205/rank-3-icon.jpg" alt="">
          <img :src="ylist.rankList[2].headImg" alt="" />
          <p>
            {{ ylist.rankList[2].nickName }}
            <span class="colors">{{
              ylist.rankList[2].userid == ylist.myRank.userid ? "(我)" : ""
            }}</span>
          </p>
          <p>{{ ylist.rankList[2].userDataFormat }}</p>
          <p style="background: rgba(255, 117, 69, 0.09);color:rgba(205, 110, 70, 1)!important;"><span>奖{{ylist.rankList[2].awardCoinFormat}}</span><img src="https://ppyos.xijiuyou.com/202205/msg-icon.jpg" alt=""></p>
        </div>
      </div>
      <!--  -->
      <div
        class="profit-list-my"
        v-if="
          (ylist.myRank &&Number(ylist.myRank.ranking) >= 30  && ylist.myRank.userData !=0) || !ycheck && Number(ylist.myRank.ranking) >= 3
        "
      >
        <div>
          <p>{{ !ylisttrue?'30+':ylist.myRank.ranking }}</p>
          <img :src="ylist.myRank.headImg" alt="" />
          <p>{{ ylist.myRank.nickName }}（我）</p>
        </div>
        <div>{{ ylist.myRank.userDataFormat }}</div>
        <div :style="(ylist.myRank &&Number(ylist.myRank.ranking) >= 30  && ylist.myRank.userData !=0) || !ycheck && Number(ylist.myRank.ranking) >= 3?'background:#ffffff;':''">{{(ylist.myRank &&Number(ylist.myRank.ranking) >= 30  && ylist.myRank.userData !=0) || !ycheck && Number(ylist.myRank.ranking) >= 3?'暂未上榜':'奖'}} {{(ylist.myRank &&Number(ylist.myRank.ranking) >= 30  && ylist.myRank.userData !=0) || !ycheck && Number(ylist.myRank.ranking) >= 3?'':ylist.myRank.awardCoinFormat}}
          <img v-if="
          !((ylist.myRank &&Number(ylist.myRank.ranking) >= 30  && ylist.myRank.userData !=0) || !ycheck && Number(ylist.myRank.ranking) >= 3)
        " src="https://ppyos.xijiuyou.com/202205/msg-icon.jpg" alt="">
        </div>
      </div>
      <template v-for="(item, index) in ylist.rankList">
        <div
          class="profit-list profit-list-bottom"
           v-if="ycheck && index>2"
          :key="index"
          :style="
            item.userid == ylist.myRank.userid
              ? 'background: linear-gradient(270deg,rgba(76, 221, 208, 0.1) 0%,rgba(0, 163, 255, 0.1) 100%);color:rgba(0, 163, 255, 1);'
              : ''
          "
        >
          <div>
            <p :style="
            item.userid == ylist.myRank.userid
              ? 'color:rgba(0, 163, 255, 1);'
              : ''
          ">{{ item.ranking }}</p>
            <img :src="item.headImg" alt="" />
            <p :style="
            item.userid == ylist.myRank.userid
              ? 'color:rgba(0, 163, 255, 1);'
              : ''
          ">{{ item.nickName }}{{ item.userid == ylist.myRank.userid?'(我)':''}}</p>
          </div>
          <div :style="
            item.userid == ylist.myRank.userid
              ? 'color:rgba(0, 163, 255, 1);'
              : ''
          ">{{ item.userDataFormat }}</div>
          <div><span>奖{{item.awardCoinFormat}}</span><img src="https://ppyos.xijiuyou.com/202205/msg-icon.jpg" alt=""></div>
        </div>
      </template>
      <div class="yesterday-bottom" @click="check(0)">
        <p>{{ !ycheck ? "查看更多" : "收起" }}</p>
        <img
          :style="
            ycheck ? 'border-radius:50%/50%;transform:rotate(180deg);' : ''
          "
          src="https://ppyos.xijiuyou.com/202205/down-icon.jpg"
          alt=""
        />
      </div>
    </div>
    <div class="guide" v-if="guide" @click="guidecontent"></div>
    <xwpopup :isshowpopup="show" @close="closeshow()" :hiddenClose="false">
      <div class="showcaibei"   >
        <p v-if="dialogData">{{dialogData.tips}}</p>
        <p v-if="dialogData"><span>x</span>{{dialogData.prize?dialogData.prize.count:''}}</p>
        <div @click="closeshows()">玩一把“开心钓鱼”</div>
      </div>
    </xwpopup>
  </div>
</template>
<script>
import xwpopup from "../../components/Popupbox.vue";
import caibei from "../../api/caibei";
import game from "../../api/game";
import Chest from "../../api/Chest";
import { Toast } from "vant";
import unit from "../../utils/unit";
export default {
  components: {
    xwpopup,
  },
  data() {
    return {
      clonelist: false,
      money: "",
      yinterval: 0,
      xinterval: 0,
      ylist: {},
      ylisttrue:false,
      xlist: {},
      xlisttrue:false,
      ycheck: true,
      xcheck: false,
      bannerlist: [],
      pklist: [],
      sjlist: [],
      guide:false,
      show:false,
      dialogData:{},
      advertisement:{},
      caibeiviptime:0,
    };
  },
  created() {
    const that =this;
    this.exchangebalance();
    this.ycoinRanking();
    // this.xcoinRanking();
    this.gameList();
    this.gameListpk();
    this.gameListsj();
    this.dialogShower();
    if(localStorage.getItem("guide")){
      this.guide = false;
    }else{
      this.guide = true;
    }
    document.addEventListener("click", (e) => {
      if (this.$refs.showPanel) {
        let isSelf = this.$refs.showPanel.contains(e.target);
        if (!isSelf) {
          this.clonelist = false;
        }
      }
    });
    document.addEventListener("visibilitychange",()=>{
        that.exchangebalance();
        that.ycoinRanking();
        // that.xcoinRanking();
        that.gameList();
    });
    this.pccode();
    this.conchVipAvailableTime();
  },
  destroyed(){
    document.removeEventListener("visibilitychange",()=>{});
  },
  methods: {
    unitauthorize(name){
      return unit.authorize(name)
    },
    newtimeday(time){
      //return parseInt(time/1000/3600/24)
      if(parseInt(time/1000/3600/24)>0){
        return  `剩余 ${parseInt(time/1000/3600/24)} 日 ${parseInt((time/1000/3600)%24)} 小时`
      }else{
       // return `剩余 ${parseInt((time/1000/3600)%24)} 小时`
       if(parseInt((time/1000/3600)%24) >= 1){
        return `剩余 ${parseInt((time/1000/3600)%24)} 小时`
       }else{
        return `剩余 < 1 小时`;
       }
      }
    },
    newtimedayS(time){
      if(parseInt(time/1000/3600/24)>0){
        return  `剩余${parseInt(time/1000/3600/24)}日${parseInt((time/1000/3600)%24)}小时`
      }else{
       // return `剩余 ${parseInt((time/1000/3600)%24)} 小时`
       if(parseInt((time/1000/3600)%24) >= 1){
        return `剩余${parseInt((time/1000/3600)%24)}小时`
       }else{
        return `剩余 < 1小时`;
       }
      }
    },
    pccode(){
      game.pccode().then(res=>{
        this.advertisement= res.data;
      })
    },
    conchVipAvailableTime(){
      Chest.conchVipAvailableTime().then(res=>{
        this.caibeiviptime = res.data;
      })
    },
    dialogShower(){
      game.dialogShower().then(res=>{
        this.dialogData = res.data.dialogData
        this.show = res.data.show
      })
    },
    closeshow(){
      this.show = !this.show;
    },
    closeshows(){
      const that = this;
      if(that.dialogData.closeAction === ""){
        that.show = !that.show;
      }else{
        window.location.href = that.dialogData.closeAction;
      }
    },
    guidecontent(){
      localStorage.setItem("guide",1);
      this.guide = false;
    },
    gameListpk() {
      game
        .gameList({
          qType: 1,
          page: 1,
          pageSize: 10,
        })
        .then((res) => {
          this.pklist = res.list;
        });
    },
    //赏金小游戏
    reward(item) {
      console.log(item.sgid)
      try {
        window.android.trackEvent(`sgame_award_game_click`,item.sgid);
      } catch (err) {
        console.log(err);
      }
      try {
        window.android.createNewWeb(
          window.location.href.split("game")[0] + "reward?sgid=" + item.sgid
        );
      } catch (e) {
        Toast("您当前app版本过低请更新app");
      }
    },
    carvesd() {
      try {
        window.android.createNewWeb(
          window.location.href.split("game")[0] + "majiang"
        );
      } catch (e) {
        Toast("您当前app版本过低请更新app");
      }
    },
    caibeivips(){
      try {
        window.android.createNewWeb(
          window.location.href.split("game")[0] + "caibeivip"
        );
      } catch (e) {
        Toast("您当前app版本过低请更新app");
      }
    },
    arena(item) {
      try {
        window.android.trackEvent(`sgame_pk_game_click`,item.sgid);
      } catch (err) {
        console.log(err);
      }
      if (item.sourceType == 0) {
        try {
          // this.$router.push({
          //   name: "arena",
          //   query:{
          //     sgid:item.sgid
          //   }
          // });
          window.android.createNewWeb(
            window.location.href.split("game")[0] + "arena?sgid=" + item.sgid
          );
        } catch (e) {
          Toast("您当前app版本过低请更新app");
        }
      } else {
        this.gogame(item.sgid);
      }
    },
    // 开始小游戏
    gogame(sgid) {
      game.smartstart({ gid: sgid }).then((res) => {
        try {
          window.android.openH5Game(res.data.link);
        } catch (err) {
          console.log(err);
        }
      });
    },
    // 赏金小游戏
    gameListsj() {
      game
        .gameList({
          qType: 2,
          page: 1,
          pageSize: 4,
        })
        .then((res) => {
          this.sjlist = res.list;
        });
    },
    start(item) {
      try {
        window.android.trackEvent(`sgame_coin_game_click`,item.sgid);
      } catch (err) {
        console.log(err);
      }
      if(item.sourceType === 0){
        game.smartstart({ gid: item.sgid }).then((res) => {
          try {
            window.android.openH5Game(res.data.link);
          } catch (err) {
            console.log(err);
          }
        });
      }else{
        try {
          window.android.createNewWeb(
            item.jumpTo
          );
        } catch (e) {
          Toast("您当前app版本过低请更新app");
        }
      }
      
    },
    gameList() {
      game.gameList({ QType: 4 }).then((res) => {
        this.bannerlist = res.list;
      });
    },
    yesterday(index) {
      if (index === 0) {
        if (this.yinterval === 0) {
          this.yinterval = -1;
        } else {
          this.yinterval = 0;
        }
        this.ycoinRanking();
      } else {
        if (this.xinterval === 0) {
          this.xinterval = -1;
        } else {
          this.xinterval = 0;
        }
        // this.xcoinRanking();
      }
    },
    check(index) {
      if (index === 0) {
        this.ycheck = !this.ycheck;
      } else {
        this.xcheck = !this.xcheck;
      }
    },
    ycoinRanking() {
      caibei
        .coinRanking({
          interval: this.yinterval,
          top: 30,
          type: 2,
        })
        .then((res) => {
          this.ylist = res.data;
          if(res.data.rankList){
            res.data.rankList.map(res_=>{
              if(res_.userid === res.data.myRank.userid){
                this.ylisttrue = true;
              }
            })
          }
        });
    },
    // xcoinRanking() {
    //   caibei
    //     .coinRanking({
    //       interval: this.xinterval,
    //       top: 30,
    //       type: 1,
    //     })
    //     .then((res) => {
    //       this.xlist = res.data;
    //       res.data.rankList.map(res_=>{
    //         if(res_.userid ===res.data.myRank.userid){
    //           this.xlisttrue = true;
    //         }
    //       })
    //     });
    // },
    exchangebalance() {
      caibei.exchangebalance().then((res) => {
        this.money = res.data.balance;
      });
    },
    nivergame(){
      // this.$router.push({
      //   name: "caibei",
      // });
      try {
        window.android.trackEvent(`sgame_more_game_open`,"");
      } catch (err) {
        console.log(err);
      }
      try {
        window.android.createNewWeb(
          window.location.href.split("game")[0] + "caibei"
        );
      } catch (e) {
        Toast("您当前app版本过低请更新app");
      }
    },
    clone() {
      this.clonelist = !this.clonelist;
    },
    exchange(index) {
      // this.$router.push({
      //   name: "caibeiexchange",
      // });
      try {
        if(index === 0){
          window.android.trackEvent(`sgame_exchange_click`,"");
        }else if(index === 1){
          window.android.trackEvent(`sgame_coin_shop_click`,"");
        }else{
          window.android.trackEvent(`sgame_coin_box_click`,"");
        }
      } catch (e) {
        Toast("您当前app版本过低请更新app");
      }
      try {
        window.android.createNewWeb(
          window.location.href.split("game")[0] + "caibeiexchange?table="+index
        );
      } catch (e) {
        Toast("您当前app版本过低请更新app");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.caibei {
  padding: 20px 12px 10px;
  box-sizing: border-box;
  background: #f6f6f6;
  .caibei-head-vip{
    border-radius: 12px;
    padding-top:0px;
    box-sizing: border-box;
    .headvip{
      display: flex;
      // justify-content: space-between;
      // align-items: center;
      padding: 7px 0;
      // padding-left: 9px;
      position: relative;
      div{
        position: absolute;
        width: 100%;
        background: linear-gradient(270deg, #B9F0FC 0%, #BCF7BE 100%);
        box-shadow: 0px -1px 0px 0px #FFFFFF;
        border-radius: 10px 10px 0px 0px;
        bottom: -10px;
        right: 0;
        padding:6px 0 12px;
        font-size: 10px;
        font-family: AlimamaShuHeiTi-Bold;
        font-weight: bold;
        color: RGBA(0, 143, 75, 1);
        text-align: center;
        span{
          color: #444444;
          font-weight: 400;
          font-family: PingFangSC-Regular, PingFang SC;
          margin-left: 3px;
        }
      }
    }
  }
  &-head {
    width: 100%;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding:  10px 11px 17px 6px;
    margin-bottom: 8px;
    position: relative;
    z-index: 1;
    div {
      width: 100%;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #444444;
      .sleftsdiv {
        display: flex;
        align-items: center;
        img {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
        .sdiv {
          position: relative;
          display: flex;
          width: 12px;
          height: 12px;
          background: #888888;
          border-radius: 50%;
          color: #ffffff;
          font-size: 10px;
          justify-content: center;
          align-items: center;
          margin-left: 5px;
          p {
            position: absolute;
            background: rgba(0, 0, 0, 0.6);
            padding: 8px 12px;
            width: 250px;
            z-index: 10;
            left: -50px;
            top: 20px;
            border-radius: 5px;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            span {
              position: absolute;
              border: 5px solid rgba(0, 0, 0, 0);
              border-bottom: 5px solid rgba(0, 0, 0, 0.6);
              top: -10px;
              left: 50px;
            }
          }
        }
        .moneys{
          font-size: 28px;
          font-family: DINOffcPro-Medi, DINOffcPro;
          font-weight: normal;
          color: #444444;
          margin-left: 14px;
          margin-top:2px;
        }
      }
    }
    &-btn {
      display: flex;
      align-items: center;
      width: 100%;
      position: relative;
      justify-content: space-between;
      margin-top: 10px;
      .gobaox{
        background: linear-gradient(180deg, #FF647D 0%, #FF7123 100%);
        box-shadow: inset 0px 2px 0px 0px rgba(255,246,229,0.39);
        border-radius: 15px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        width: 69px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        display: block;
        padding-top:1px;
      }
      div{
        display: flex;
        width: 50%;
        div{
          display: inline-block;
          width: 60px;
          height: 30px;
          background: linear-gradient(180deg, #D199FF 0%, #78C1FF 100%);
          box-shadow: inset 0px 2px 0px 0px rgba(255,246,229,0.39);
          border-radius: 20px 4px 4px 20px;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          text-align: center;
          line-height: 30px;
        }
        div:nth-child(2){
          border-radius: 4px 20px 20px 4px;
          margin-left: 4px;
        }
      }
    }
  }
  .banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .banner-l{
      width: 50%;
      img{
        width: calc(100% - 6px);
        height: 100%;
      }
      p{
        bottom: 0;
        top: 50px;
        left: 13px;
      }
    }
    .banner-r{
      width: 50%;
      padding-top: 2px;
      img{
        width: calc(100% - 6px);
      }
      div{
        text-align: left;
        margin-bottom: 4px;
      }
      div:nth-child(4) {
        text-align: right;
        // img{
        //   margin-top: 4px;
        // }
      }
    }
    div {
      position: relative;
    }
    // div:nth-child(2) {
    //   text-align: right;
    // }
    img {
      width: calc(100% - 6px);
    }
    p {
      position: absolute;
      left: 20px;
      bottom: 19px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
  }
  .yesterday {
    width: 100%;
    background: #ffffff;
    border-radius: 10px;
    margin-bottom: 10px;
    &-titles{
      width: 100%;
      text-align: center;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      margin-top: -15px;
      margin-bottom: 26px;
    }
    .profit-list {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 8px);
      height: 68px;
      align-items: center;
      margin: 0 auto;
      padding: 0 10px;
      p{
        img{
          width: 16px;
          height: 16px;
        }
      }
      div {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-family: DINOffcPro-Medi, DINOffcPro;
        font-weight: normal;
        color: #00a3ff;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-left: 11px;
          margin-right: 17px;
        }
        p:nth-child(3) {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #00a3ff;
        }
      }
    }
    .profit-list-bottom {
      div {
        color: #444444;
        p:nth-child(1){
          width: 20px;
          text-align: center;
        }
        p:nth-child(3) {
          color: #444444;
          width: 60px;
          overflow: hidden;/*超出部分隐藏*/
          white-space: nowrap;/*不换行*/
          text-overflow:ellipsis;
        }
      }
      div:nth-child(3){
        width: 80px;
        height: 22px;
        background: linear-gradient(270deg, #F0F5FF 0%, #F8F0FF 100%);
        border-radius: 14px;
        font-size: 13px;
        font-family: DINOffcPro-Medi, DINOffcPro;
        font-weight: 500;
        color: #797AE5;
        justify-content: center;
        display: flex;
        align-items: center;
        padding-top: 1px;
        span{
          margin-top: 3px;
        }
        img{
          width: 16px;
          height: 16px;
          margin: 0 0 0 2px;
        }
      }
    }
    .profit-list-my {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 8px);
      height: 68px;
      border-radius: 6px;
      background: linear-gradient(
        270deg,
        rgba(76, 221, 208, 0.1) 0%,
        rgba(0, 163, 255, 0.1) 100%
      );
      align-items: center;
      margin: 5px auto 0;
      padding: 0 10px;
      p:nth-child(1){
        width: 20px;
        text-align: center;
      }
      div {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-family: DINOffcPro-Medi, DINOffcPro;
        font-weight: normal;
        color: #00a3ff;
        padding-top: 2px;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-left: 11px;
          margin-right: 17px;
        }
        p:nth-child(3) {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #00a3ff;
          width: 60px;
          overflow: hidden;/*超出部分隐藏*/
          white-space: nowrap;/*不换行*/
          text-overflow:ellipsis;
        }
      }
      div:nth-child(3){
        width: 80px;
        height: 22px;
        background: linear-gradient(270deg, #F0F5FF 0%, #F8F0FF 100%);
        border-radius: 14px;
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #797AE5;
        justify-content: center;
        span{
          font-size: 16px;
        }
        img{
          width: 16px;
          height: 16px;
          margin: 0 0 0 2px;
        }
      }
    }
    .profit-list-my-bottom {
      background: linear-gradient(
        270deg,
        rgba(190, 111, 255, 0.1),
        rgba(190, 111, 255, 0.1) 100%
      );
      div {
        color: #be6fff;
        p:nth-child(3) {
          color: #be6fff;
        }
      }
    }
    &-head {
      width: 100%;
      height: 57px;
      border: 1px solid #ffffff;
      background: linear-gradient(
        360deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(0, 163, 255, 0.1) 100%
      );
      border-radius: 10px;
      position: relative;
      text-align: center;
      img {
        padding-top: 14px;
        width: 160px;
      }
      div {
        width: 50px;
        height: 20px;
        background: rgba(0, 163, 255, 0.15);
        border-radius: 10px;
        font-size: 10px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #00a3ff;
        position: absolute;
        left: 18px;
        top: 13px;
        line-height: 20px;
      }
    }
    &-headbottom {
      background: linear-gradient(
        360deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(190, 111, 255, 0.1) 100%
      );
      div {
        background: rgba(190, 111, 255, 0.15);
        color: rgba(190, 111, 255, 1);
      }
    }
    &-list {
      display: flex;
      justify-content: space-around;
      padding: 0 4px 5px;
      div:nth-child(1) {
        margin: 0;
        width: 115px;
        p:nth-child(1) {
          background: #b5d8fa;
          color: #45566d;
        }
      }
      div:nth-child(3) {
        width: 115px;
        p:nth-child(1) {
          background: rgba(247, 156, 119, 1);
          color: rgba(126, 66, 43, 1);
        }
        // p:nth-child(4) {
        //   color: rgba(205, 110, 70, 1);
        // }
      }
      div:nth-child(2){
        height: 135px;
        margin-top: -10px;
        img:nth-child(1) {
          position: absolute;
          width: 25px;
          height: 25px;
          left: 39px;
          top: 0px;
          border:0px;
        }
        // p:nth-child(4) {
        //   color: rgba(255, 153, 0, 1);
        // }
      }
      div {
        width: 145px;
        height: 155px;
        text-align: center;
        padding-bottom: 12px;
        position: relative;
        margin-left: -20px;
        padding-top: 10px;
        p:nth-child(1) {
          position: absolute;
          width: 26px;
          height: 26px;
          background: #ffc700;
          border-radius: 6px 6px 12px 6px;
          border: 2px solid #ffffff;
          font-size: 17px;
          font-family: v16-front;
          color: #884d00;
          line-height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 2px solid #ffffff;
          left: 15px;
          top: 2px;
        }
        img:nth-child(1) {
          position: absolute;
          width: 25px;
          height: 25px;
          left: 24px;
          top: 0px;
          border:0px;
        }
        p:nth-child(3) {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #444444;
          margin-bottom: 8px;
          margin-top: 5px;
          span {
            color: rgba(190, 111, 255, 1);
          }
          .colors {
            color: rgba(0, 163, 255, 1);
          }
        }
        p:nth-child(4) {
          font-size: 16px;
          font-family: DINOffcPro-Medi, DINOffcPro;
          font-weight: normal;
          color: #444444;
        }
        p:nth-child(5) {
          width: 85px!important;
          height: 22px!important;
          font-size: 13px!important;
          font-family: DINOffcPro-Medi, DINOffcPro!important;
          font-weight: 500!important;
          color: #4E7AB5!important;
          margin: 5px auto 0;
          background: rgba(101, 130, 200, 0.09);
          border-radius: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            position: relative!important;
            width: 16px!important;
            height: 16px!important;
            padding: 0!important;
            margin: 0 0 0 2px!important;
            border: 0!important;
            left:auto!important;
            top: auto!important;
          }
          span{
            margin-top: 3px;
          }
        }
        img {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          margin-top: 8px;
          border: 3px solid rgba(255, 189, 2, 1);
        }
      }
      div:nth-child(2) {
         border-radius: 10px;
        background: linear-gradient(360deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 178, 0, 0.15) 100%);
      }
      div:nth-child(1) { 
        background: linear-gradient(360deg, rgba(255, 255, 255, 0.15) 0%, rgba(0, 116, 255, 0.15) 100%);
        border-radius: 10px;
        img{
          border: 3px solid rgba(130, 181, 255, 1);
        }
        img:nth-child(1) {
          border:0px;
        }
      }
      div:nth-child(3) {
        background: linear-gradient(360deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 86, 11, 0.15) 100%);
        border-radius: 10px;
        img{
          border: 3px solid rgba(247, 156, 119, 1);
        }
        img:nth-child(1) {
          border:0px;
        }
      }
    }
    &-list-num {
      div {
        p:nth-child(4) {
          font-size: 16px;
          font-family: DINOffcPro-Medi, DINOffcPro;
          font-weight: normal;
          color: #be6fff;
        }
      }
    }
    &-bottom {
      width: calc(100% - 20px);
      display: flex;
      align-items: center;
      background: linear-gradient(180deg, #f6f6f6 0%, #ffffff 100%);
      border-radius: 6px;
      height: 36px;
      justify-content: center;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #444444;
      margin: 10px auto 0;
      img {
        width: 8px;
        height: 5px;
        margin-left: 6px;
      }
    }
  }
}
.carve-sd{
  width: calc(100% - 6px);
  margin-top: 5px;
  margin-bottom: 5px;
}
.arena {
  width: 100%;
  padding: 12px 12px 0 12px;
  background: #ffffff;
  margin: 0 auto 0;
  border-radius: 15px;
}
.arena-top {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
.arena-top span {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-right: 4px;
}

.arena-top img {
  width: 18px;
  margin-right: 4px;
}
.arena—center {
  display: flex;
  margin-top: 19px;
  flex-wrap: wrap;
}
.arena—center img {
  width: 71px;
  height: 71px;
  border-radius: 10px;
}
.arena—center div {
  margin-bottom: 10px;
  text-align: center;
  width: 25%;
}
.arena—center div p:nth-child(2) {
  font-size: 14px;
  font-family: PingFangSC-Medi, PingFang SC;
  font-weight: 400;
  color: #333333;
  white-space: nowrap;
  text-align: center;
}

.arena—center div p:nth-child(3) {
  font-size: 16px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  color: #ff5675;
  text-align: center;
}
.van-cell {
  width: 21%;
  padding: 0;
  margin-right: 4%;
}
.arena—center div div p:nth-child(3) span {
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff5675;
}

.reward {
  width: 100%;
  padding: 12px;
  background: #ffffff;
  margin: 10px auto 0;
  border-radius: 15px;
}
.reward-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.reward-center img {
  width: 68px;
  height: 68px;
  margin-right: 10px;
  border-radius: 10px;
}

.reward-center div {
  display: flex;
  align-items: center;
  margin-top: 12px;
  width: 50%;
}
.reward-center div div {
  display: block;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.reward-center div div p:nth-child(2) {
  font-size: 16px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  color: #ff5675;
}
.gameadd{
  width: 100%;
  height: 38px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  margin: 8px 0;
  align-items: center;
  border-radius: 15px;
  span{
    margin: 0 5px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #444444;
  }
  img:nth-child(1){
    width: 20px;
    height: 16px;
  }
  img:nth-child(3){
    width: 5px;
    height: 8px;
  }
}
.guide{
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: url("https://ppyos.xijiuyou.com/202209/guide-new-5.png") no-repeat;
  background-size: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}
.showcaibei{
  width: 295px;
  height: 265px;
  margin: 150px auto 0;
  background: url("https://ppyos.xijiuyou.com/202209/coin-newer-bg.png") no-repeat;
  background-size: 100%;
  position: relative;
  padding-top: 18px;
  box-sizing: border-box;
  text-align: center;
  p{
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #444444;
    span{
      font-size: 20px;
      margin-right: 5px;
    }
  }
  p:nth-child(2){
    font-size: 31px;
    font-style:italic;
    font-family: Source-KeynoteartHans-Regular, Source-KeynoteartHans;
    font-weight: bold;
    color: #BE6FFF;
    line-height: 34px;
    text-shadow: 0px -1px 0px #C447FF, -2px 2px 1px #FFFFFF;
    // text-shadow: 0px 4px 0px #FFFFFF, 0px 2px 1px rgba(255,255,255,0.5);
   // background: linear-gradient(180deg, #C447FF 0%, #6222FF 100%);
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255,255,255,0.5) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 18px;
  }
  div{
    position: absolute;
    width: 196px;
    height: 62px;
    background: url("https://ppyos.xijiuyou.com/202209/coin-newer-btn.png") no-repeat;
    background-size: 100%;
    bottom: 0;
    left: 52px;
    font-size: 16px;
    font-family: AlibabaPuHuiTiB;
    color: #FFFFFF;
    padding-top: 18px;
    text-align: center;
  }
}
</style>